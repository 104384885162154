/**
 * Created by gschulz on 26.08.15.
 */
let MessageTool = (function () {

    let _private = {
        instance: this
    };

    _private.interval = 30;

    _private.instance = this;

    _private.latestMessage = {
        object_id: 0
    };

    _private.messageLoaded = false;

    _private.messageResponse = {};

    this.messageSystemKeys = {
        frat_locked:   {
            liClass:   'frat',
            highlight: true,
            sound:     false
        },
        frat_reviewed: {
            liClass:   'frat',
            highlight: true,
            sound:     false
        }
    };

    this.init = function () {
        if (!isLogged) {
            return;
        }
        _private.initMessagePolling();
        _private.intercom = Intercom.getInstance();
        _private.intercom.on('messageResponse', function (data) {
            _private.messageResponse      = data;
            _private.messageResponse.time = new Date().getTime();
        });
        _private.intercom.on('pageReload', function () {
            location.reload();
        });
        return this;
    };

    _private.initMessagePolling = function () {
        setTimeout(() => {
            if (!_.isUndefined(_private.messageResponse.time) &&
                _private.messageResponse.time > new Date().getTime() - _private.interval * 1000) {
                _private.handleResponse(_private.messageResponse, true);
                return;
            }
            _private.loadMessages(true);
        }, _private.interval * 1000);
    };

    /**
     *
     * @param {boolean} [initPolling]
     */
    _private.loadMessages = function (initPolling) {
        $.ajax({
            dataType: 'json',
            url:      urlHelper.getSimpleUrl('message', 'index'),
            type:     'get',
            data:     {raw: 1, filter: {is_hidden: {'in': [1, 0]}}},
            error:    function (e) {
                /**
                 * no connection
                 */
                if (!e.status && initPolling) {
                    _private.initMessagePolling();
                }
            },
            success:  function (r) {
                _private.intercom.emit('messageResponse', r);
                _private.handleResponse(r, initPolling);
            }
        });
    };

    /**
     *
     * @param {object} r
     * @param {boolean} [initPolling]
     */
    _private.handleResponse = function (r, initPolling) {
        if (_.isUndefined(r.response) || (!_.isUndefined(r['error_key']) && r['error_key'] !== 'load_data_ok')) {
            Intercom.destroy();
            if (r['error_key'] === 'maintenance_mode' && urlHelper.getState().route.controller !== 'maintenance') {
                location.href = urlHelper.getSimpleUrl('maintenance', 'index');
            }
            if (r['error_key'] === 'unauthorized') {
                location.href = urlHelper.getSimpleUrl('auth', 'login');
            }
            return;
        }
        _private.updateMessagePopUp(r.response.result);
        if (!_.isUndefined(r.response.result[0])) {
            _private.latestMessage = r.response.result[0];
        }
        if (initPolling) {
            _private.initMessagePolling();
        }
        _private.messageLoaded = true;
    };

    /**
     *
     * @param {*} result
     */
    _private.updateMessagePopUp = function (result) {
        let addNewMessage = false;
        $.each(result, function (i, res) {
            if (!_.isUndefined(_private.latestMessage.object_id) &&
                _private.latestMessage.object_id < res.object_id) {
                addNewMessage = true;
                if (_private.messageLoaded) {
                    $('body').trigger('MessageToolAddMessage', res);
                    _private.highlightIndicator(res['system_key']);
                }
            }
        });
    };

    _private.highlightIndicator = function (systemKey) {
        let systemKeyOption = {
            liClass:   'dashboard',
            highlight: true,
            sound:     false
        };
        if (!_.isUndefined(_private.instance.messageSystemKeys[systemKey])) {
            systemKeyOption = _private.instance.messageSystemKeys[systemKey];
        }
        if (systemKeyOption.highlight) {
            new Navigation().highlight(systemKeyOption.liClass, true);
        }
        if (systemKeyOption.sound) {
            document.getElementById('highlight-sound').play();
        }
    };
});